import React from 'react';

import FrontSection1 from 'components/FrontSection1';
import FrontSection2 from 'components/FrontSection2';
import FrontSection3 from 'components/FrontSection3';
import FrontSection4 from 'components/FrontSection4';
import FrontSection5 from 'components/FrontSection5';
import FrontSection6 from 'components/FrontSection6';

const HomePage = () => (
  <>
    <FrontSection1 />
    <FrontSection2 />
    <FrontSection3 />
    <FrontSection4 />
    <FrontSection5 />
    <FrontSection6 />
  </>
);

export default HomePage;
