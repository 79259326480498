import React from 'react';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';

import DescWithImage from 'components/DescWithImage';

import './FrontSection5.scss';

const FrontSection5 = () => (
  <div className="front-section-5" id="section-5">
    <DescWithImage
      title={<FormattedMessage id="frontSection5Title" />}
      contentPart1={<FormattedMessage id="frontSection5Subtitle" />}
      contentPart2={(
        <div className="create-eho hidden-tablet">
          <Link
            to="/configurator/step-1"
          >
            <span>
              <FormattedMessage id="frontSection5CreateEho" />
            </span>
            <img src="/images/arrow.svg" alt="arrow" />
          </Link>
        </div>
      )}
      imgSrc="/images/front-section5-bg.jpg"
      inverted
      isFront
    />
  </div>
);

export default FrontSection5;
