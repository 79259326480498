import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ url }) => (
  <ReactPlayer
    url={url}
    width="100%"
    height="100%"
    controls
    muted
    playing
  />
);

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
};

export default VideoPlayer;
