import React, { useState } from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import classnames from 'classnames';

import 'swiper/swiper.scss';
import './FrontSection4.scss';

const slides = [{
  id: 'slide-3',
  image: '/images/swiper/panel-eho-biuro-s.jpg',
  navigationTextId: 'office',
  titleTextId: 'officeSlideTitle',
}, {
  id: 'slide-2',
  image: '/images/swiper/panel-eho-dom-s.jpg',
  navigationTextId: 'home',
  titleTextId: 'homeSlideTitle',
}, {
  id: 'slide-4',
  image: '/images/swiper/panel-eho-szkola_s.jpg',
  navigationTextId: 'school',
  titleTextId: 'schoolSlideTitle',
}, {
  id: 'slide-1',
  image: '/images/swiper/panel-eho-publiczne_s.jpg',
  navigationTextId: 'publicSpaces',
  titleTextId: 'publicSpacesSlideTitle',
}, {
  id: 'slide-5',
  image: '/images/swiper/panel-eho-restauracja_s.jpg',
  navigationTextId: 'restaurants',
  titleTextId: 'restaurantsSlideTitle',
}];

const FrontSection4 = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <div className="front-section-4 inline section-spacer" id="section-4">
      <div className="left">
        <div className="line" />
        <div className="mobile-inline">
          <div className="slide-title hidden-tablet">
            <FormattedMessage id={slides[currentSlide].titleTextId} />
          </div>
          <div className="slider-controls-wrapper">
            {
              slides.map((el, key) => (
                <div
                  className={classnames('control', {
                    active: currentSlide === key,
                  })}
                  key={el.id}
                  onClick={() => {
                    setCurrentSlide(key);
                  }}
                >
                  <div className="line" />
                  <div className="label">
                    <FormattedMessage id={el.navigationTextId} />
                  </div>
                </div>
              ))
            }
          </div>
          <div className="slide-title visible-tablet">
            <FormattedMessage id={slides[currentSlide].titleTextId} />
          </div>
        </div>
      </div>
      <div className="right">
        <div className="slider-wrapper">
          {
            slides.map((el, key) => (
              <div
                className={classnames('slide', {
                  active: currentSlide === key,
                })}
                key={el.id}
              >
                <img src={el.image} alt={el.navigationTextId} className="img-responsive" />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default FrontSection4;
