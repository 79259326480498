import React from 'react';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';

import './FrontSection6.scss';

const FrontSection6 = () => (
  <div className="front-section-6" id="section-6">
    <div className="line" />
    <div className="title">
      <FormattedMessage id="frontSection6Title" />
    </div>
    <div className="lets-chat">
      <Link
        to="/contact"
      >
        <span>
          <FormattedMessage id="frontSection6Chat" />
        </span>
        <img src="/images/arrow.svg" alt="arrow" />
      </Link>
    </div>
  </div>
);

export default FrontSection6;
