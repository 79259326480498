import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

import VideoPlayer from 'components/VideoPlayer';

import './FrontSection1.scss';

const FrontSection1 = () => (
  <div className="front-section-1 inline" id="section-1">
    <div className="left">
      <div className="line" />
      <div className="image-wrapper">
        <img src="/images/eho.svg" alt="eho" />
      </div>
      <h1 className="subtitle">
        <FormattedMessage id="frontSection1Subtitle" />
      </h1>
      <div className="description">
        <FormattedMessage id="frontSection1Title" />
      </div>
      <div className="arrow hidden-tablet">
        <a href="#section-2">
          <img src="/images/arrow.svg" alt="arrow" />
          <span>
            <FormattedMessage id="frontSection1ArrowDesc" />
          </span>
        </a>
      </div>
    </div>
    <div className="right">
      <div className="video-wrapper">
        <VideoPlayer
          url="https://l2.polswim.pl/panel-eho-top-hires-1.mp4"
        />
      </div>
    </div>
  </div>
);

export default FrontSection1;
