import React from 'react';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';

import './FrontSection2.scss';

const FrontSection2 = () => (
  <div className="front-section-2" id="section-2">
    <div className="line" />
    <div className="title">
      <FormattedMessage id="frontSection2Title" />
    </div>
    <div className="boxes-wrapper">
      <div className="box">
        <img src="/images/front-section2-box1.png" className="img-responsive" alt="" />
        <div className="box-title">
          <FormattedMessage id="frontSection2Box1Title" />
        </div>
        <div className="box-subtitle">
          <FormattedMessage id="frontSection2Box1Subtitle" />
        </div>
      </div>
      <div className="box">
        <img src="/images/front-section2-box2.png" className="img-responsive" alt="" />
        <div className="box-title">
          <FormattedMessage id="frontSection2Box2Title" />
        </div>
        <div className="box-subtitle">
          <FormattedMessage id="frontSection2Box2Subtitle" />
        </div>
      </div>
      <div className="box">
        <img src="/images/front-section2-box3.png" className="img-responsive" alt="" />
        <div className="box-title">
          <FormattedMessage id="frontSection2Box3Title" />
        </div>
        <div className="box-subtitle">
          <FormattedMessage id="frontSection2Box3Subtitle" />
        </div>
      </div>
    </div>
    <div className="read-more">
      <Link
        to="/products"
      >
        <span>
          <FormattedMessage id="frontSection2ReadMore" />
        </span>
        <img src="/images/arrow.svg" alt="arrow" />
      </Link>
    </div>
  </div>
);

export default FrontSection2;
