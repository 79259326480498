import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

import DescWithImage from 'components/DescWithImage';

import './FrontSection3.scss';

const FrontSection3 = () => (
  <div className="front-section-3" id="section-3">
    <DescWithImage
      title={<FormattedMessage id="frontSection3Title" />}
      contentPart1={<FormattedMessage id="frontSection3Subtitle" />}
      imgSrc="/images/front-section3-bg.png"
      inverted
      isFront
    />
  </div>
);

export default FrontSection3;
